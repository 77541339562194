@import 'fonts';
@import 'variables';
@import 'screensize';
@import 'math';
@import 'typo';
@import 'ui';
@import 'loader';
@import 'content';
@import 'about';
@import 'cookies';

* {
  margin: 0;
  padding: 0;

  box-sizing: border-box;
}

html,
body {
  height: 100%;
  min-height: 100%;

  font-family: $root-font-family;
  font-smooth: always;

  color: $darkBlue;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.dark {
    color: white;
  }
}

// helpers to get correct screensize in JS
body {
  &:after {
    display: none;

    content: 'small';

    @include medium {
      content: 'medium';
    }
    @include large {
      content: 'large';
    }
    @include xLarge {
      content: 'xlarge';
    }
  }
}

a {
  color: currentColor;
  text-decoration: none;
}

button {
  cursor: pointer;

  font-family: inherit;

  border: none;

  appearance: none;
}

#app {
  position: relative;

  width: 100%;
  height: 100%;

  overflow-x: hidden;
}

#canvas {
  position: fixed;

  width: 100%;
  height: 100%;
}

.video-drawer {
  position: absolute;
  top: 0;
  right: 0;

  display: none;

  padding: 80px 2rem;
  width: 100%;
  max-width: 400px;
  min-height: 100%;

  color: black;

  background: white;

  &.show {
    display: block;
  }

  h2 {
    margin-bottom: 20px;

    font-size: 24px;
    font-weight: 300;

    color: $darkBlue;
    text-transform: initial;
  }

  h3 {
    margin-bottom: 2rem;

    font-size: 20px;

    color: $darkBlue;
  }

  p {
    color: $darkBlue;
  }

  .player {
    position: relative;

    margin-bottom: 10px;
    // assume  16:9
    padding-bottom: 56.25%;

    background: $darkBlue;

    iframe {
      position: absolute;
    }
    svg {
      position: absolute;
      top: 50%;
      left: 50%;

      width: 40px;

      transform: translate(-50%, -50%);

      fill: rgba(white, 0.5);
    }
  }
}

.start-btn {
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 0 auto;
  width: 50px;
  height: 50px;

  border-radius: 50%;

  background: white;

  svg {
    width: 25px;
    height: 25px;

    fill: $lightBlue;
  }
}

.close-btn {
  position: absolute;
  top: 2rem;
  right: 2rem;

  color: $darkBlue;

  border: none;
  border-bottom: 2px solid $blue;

  background: none;
}
