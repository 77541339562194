.about {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  display: none;
  justify-content: center;
  align-items: center;

  .about-content-wrapper {
    position: relative;

    width: calc(100% - 4rem);
    max-width: 600px;
    //height: 600px;
    // max-height: calc(100% - 4rem);
    max-height: 70vh;

    background: white;
    box-shadow: 0 1rem 1rem rgba($darkBlue, 0.2);

    &:after {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;

      display: block;

      height: 3rem;

      content: '';
      pointer-events: none;

      background: linear-gradient(rgba(white, 0), white);
    }

    .about-content {
      padding: 5rem 2rem 2rem 2rem;
      height: 100%;
      // max-height: calc(100vh - 4rem);
      max-height: 70vh;

      overflow: scroll;

      -webkit-overflow-scrolling: touch;

      @media screen and (min-width: 768px) {
        padding: 5rem 4rem 4rem 4rem;
      }
    }

    #aboutClose {
      position: absolute;

      background-color: white;
    }

    h2,
    p {
      width: 100%;
      max-width: 600px;
    }

    p {
      margin-bottom: 1rem;
    }

    a {
      text-decoration: underline;
    }
  }
}

.dark .about {
  color: $darkBlue;
}
