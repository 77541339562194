@import 'variables';

.h1 {
  margin-bottom: 0.5rem;

  font-family: $heading-font;
  font-size: 42px;
  font-weight: 500;

  line-height: 1.2em;
  text-align: center;
  letter-spacing: 0.12em;
  text-transform: uppercase;

  @include medium {
    font-size: 72px;
  }


}

h1 {
  @extend .h1;
}

.h2 {
  margin-bottom: 15px;

  font-family: $heading-font;
  font-size: 24px;
  font-weight: 500;

  letter-spacing: 0.03em;
  text-transform: uppercase;

  @include medium {
    font-size: 40px;
  }

  @include smallHeight {
    font-size: 24px;
  }
}

h2 {
  @extend .h2;
}

.h3 {
  margin-bottom: 15px;

  font-family: $heading-font;
  font-size: 22px;
  font-weight: 300;

  @include medium {
    font-size: 34px;
  }
}

h3 {
  @extend .h3;
}

p {
  margin-bottom: 30px;

  font-size: 16px;

  line-height: 1.45em;

  @include medium {
    font-size: 18px;
  }

  @include smallHeight {
    font-size: 16px;
  }
}

.intro-text {
  font-size: 18px;

  text-align: center;
}

button {
  font-size: 18px;
}
