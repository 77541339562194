@import "https://use.typekit.net/dex0etk.css";
.h1, h1 {
  text-align: center;
  letter-spacing: .12em;
  text-transform: uppercase;
  margin-bottom: .5rem;
  font-family: futura-pt, sans-serif;
  font-size: 42px;
  font-weight: 500;
  line-height: 1.2em;
}

@media screen and (min-width: 768px) {
  .h1, h1 {
    font-size: 72px;
  }
}

.h2, .loader .text, h2 {
  letter-spacing: .03em;
  text-transform: uppercase;
  margin-bottom: 15px;
  font-family: futura-pt, sans-serif;
  font-size: 24px;
  font-weight: 500;
}

@media screen and (min-width: 768px) {
  .h2, .loader .text, h2 {
    font-size: 40px;
  }
}

@media screen and (max-height: 680px) {
  .h2, .loader .text, h2 {
    font-size: 24px;
  }
}

.h3, h3 {
  margin-bottom: 15px;
  font-family: futura-pt, sans-serif;
  font-size: 22px;
  font-weight: 300;
}

@media screen and (min-width: 768px) {
  .h3, h3 {
    font-size: 34px;
  }
}

p {
  margin-bottom: 30px;
  font-size: 16px;
  line-height: 1.45em;
}

@media screen and (min-width: 768px) {
  p {
    font-size: 18px;
  }
}

@media screen and (max-height: 680px) {
  p {
    font-size: 16px;
  }
}

.intro-text {
  text-align: center;
  font-size: 18px;
}

button {
  font-size: 18px;
}

#logo {
  width: 48px;
  font-size: inherit;
  color: #025169;
  background: none;
  position: fixed;
  top: 2rem;
  left: 2rem;
  overflow: hidden;
}

@media screen and (min-width: 768px) {
  #logo {
    width: 232px;
    top: 4rem;
    left: 4rem;
  }
}

#logo svg {
  height: 30px;
  fill: currentColor;
  transition: color .5s;
}

#logo svg .small-hide {
  display: none;
}

@media screen and (min-width: 768px) {
  #logo svg .small-hide {
    display: block;
  }
}

.menu {
  font-size: 18px;
  transition: color .5s;
  display: -ms-flexbox;
  display: flex;
  position: fixed;
  top: 2rem;
  right: 2rem;
}

@media screen and (min-width: 768px) {
  .menu {
    top: 4rem;
    right: 4rem;
  }
}

.menu ul.links {
  padding-top: 3px;
  list-style: none;
  display: -ms-flexbox;
  display: flex;
}

.menu ul.links li {
  margin-right: 15px;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}

.menu ul.links li:last-child {
  margin-right: 0;
}

.menu ul.links li.current a {
  border-bottom: none;
  font-weight: 500;
}

.menu ul.links li a {
  border-bottom: 2px solid #047fa4;
  transition: border-color .5s;
}

.menu ul.links li svg {
  height: 25px;
  fill: #047fa4;
  margin-left: 5px;
  transition: fill .5s;
}

.menu ul.lang-list {
  width: 100%;
  background: #fff;
  padding: 5px;
  display: none;
  position: absolute;
  top: 35px;
  left: 0;
  box-shadow: 0 1rem 1rem rgba(2, 81, 105, .2);
}

.menu ul.lang-list.visible {
  display: block;
}

.menu ul.lang-list li {
  margin-right: 0;
}

.menu ul.lang-list li a {
  width: 100%;
  border-bottom: none;
  padding: 5px;
  display: block;
}

.menu ul.lang-list li a:hover {
  background: rgba(202, 224, 224, .4);
}

.content-menu {
  color: #047fa4;
  transition: color .5s;
  position: fixed;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
}

@media screen and (min-width: 768px) {
  .content-menu {
    right: 30px;
  }
}

.content-menu ul {
  list-style: none;
}

.content-menu li {
  margin: 8px 0;
}

.content-menu li a {
  width: 20px;
  height: 20px;
  border: 2px solid rgba(4, 127, 164, 0);
  border-radius: 50%;
  transition: border-color .3s;
  display: block;
  position: relative;
}

@media screen and (min-width: 768px) {
  .content-menu li a {
    width: 24px;
    height: 24px;
  }
}

.content-menu li a.frontpage:before {
  display: none;
}

.content-menu li a:before {
  width: 6px;
  height: 6px;
  content: "";
  background: currentColor;
  border-radius: 50%;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (min-width: 768px) {
  .content-menu li a:before {
    width: 8px;
    height: 8px;
  }
}

.content-menu li a:hover {
  border: 2px solid #047fa4;
}

@media screen and (min-width: 768px) {
  .content-menu li a:hover span {
    visibility: visible;
    opacity: 1;
    transform: translateX(0);
  }
}

.content-menu li a.active, .content-menu li a:focus {
  border: 2px solid #047fa4;
}

.content-menu li a svg {
  width: 12px;
  fill: currentColor;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (min-width: 768px) {
  .content-menu li a svg {
    width: 14px;
  }
}

.content-menu li a span {
  display: none;
}

@media screen and (min-width: 768px) {
  .content-menu li a span {
    visibility: hidden;
    opacity: 0;
    transition: opacity .35s, transform .35s;
    display: block;
    position: absolute;
    right: 28px;
    transform: translateX(-5px);
  }
}

.dark #logo svg {
  color: #fff;
}

.dark .menu ul li a {
  border-color: #cae0e0;
}

.dark .menu ul li svg {
  fill: #cae0e0;
}

.dark .content-menu ul li a:hover, .dark .content-menu ul li a.active {
  border-color: #047fa4;
}

.h1, h1 {
  text-align: center;
  letter-spacing: .12em;
  text-transform: uppercase;
  margin-bottom: .5rem;
  font-family: futura-pt, sans-serif;
  font-size: 42px;
  font-weight: 500;
  line-height: 1.2em;
}

@media screen and (min-width: 768px) {
  .h1, h1 {
    font-size: 72px;
  }
}

.h2, .loader .text, h2 {
  letter-spacing: .03em;
  text-transform: uppercase;
  margin-bottom: 15px;
  font-family: futura-pt, sans-serif;
  font-size: 24px;
  font-weight: 500;
}

@media screen and (min-width: 768px) {
  .h2, .loader .text, h2 {
    font-size: 40px;
  }
}

@media screen and (max-height: 680px) {
  .h2, .loader .text, h2 {
    font-size: 24px;
  }
}

.h3, h3 {
  margin-bottom: 15px;
  font-family: futura-pt, sans-serif;
  font-size: 22px;
  font-weight: 300;
}

@media screen and (min-width: 768px) {
  .h3, h3 {
    font-size: 34px;
  }
}

p {
  margin-bottom: 30px;
  font-size: 16px;
  line-height: 1.45em;
}

@media screen and (min-width: 768px) {
  p {
    font-size: 18px;
  }
}

@media screen and (max-height: 680px) {
  p {
    font-size: 16px;
  }
}

.intro-text {
  text-align: center;
  font-size: 18px;
}

button {
  font-size: 18px;
}

.loader {
  width: 100%;
  height: 100%;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
}

.loader .progress {
  width: 150px;
  height: 12px;
  border: 2px solid #025169;
  border-radius: 10px;
  margin-top: 10px;
}

@media screen and (min-width: 768px) {
  .loader .progress {
    width: 160px;
  }
}

.loader .progress .bar {
  width: 0%;
  height: 100%;
  background: #025169;
}

.content {
  width: 100%;
  height: 100%;
  perspective: 700px;
  transition: color .5s;
  position: fixed;
  overflow: hidden;
}

.block {
  max-width: 35rem;
  pointer-events: none;
  display: none;
  position: absolute;
}

.block .cta {
  border-bottom: 2px solid #047fa4;
  font-size: 20px;
  font-weight: 500;
  display: inline;
}

@media screen and (max-height: 680px) {
  .block .cta {
    font-size: 18px;
  }
}

@media screen and (min-width: 768px) {
  .no-touch .block {
    pointer-events: all;
  }
}

.text-block {
  padding: 0 2rem;
}

@media screen and (min-width: 768px) {
  .text-block {
    padding: 0 4rem;
  }
}

.text-block ul {
  list-style: none;
}

.text-block ul a {
  text-decoration: underline;
}

.text-block ul a:hover {
  text-decoration: none;
}

#introText {
  width: 80%;
  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translate(-50%);
}

@media screen and (min-width: 768px) {
  #introText {
    bottom: 7%;
  }
}

#introText p {
  max-width: 20rem;
  margin: .5rem auto 1rem;
}

@media screen and (min-width: 768px) {
  #introText p {
    max-width: 30rem;
    margin: 2rem auto;
    font-size: 24px;
  }
}

.top-text {
  top: 120px;
}

@media screen and (min-width: 768px) {
  .top-text {
    top: 200px;
  }
}

.bottom-text {
  bottom: 0;
}

.push-text {
  top: 100vh;
}

.middle-text {
  top: 50%;
  transform: translateY(-50%);
}

#healthButtons {
  width: 100%;
  display: none;
  position: absolute;
  top: 90px;
}

@media screen and (min-width: 768px) {
  #healthButtons {
    top: 120px;
  }
}

#healthMessages {
  width: 100%;
  max-width: 720px;
  pointer-events: none;
  text-align: center;
  display: none;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#healthMessages p {
  width: 100%;
  padding: 0 35px;
  font-size: 22px;
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (min-width: 768px) {
  #healthMessages p {
    font-size: 34px;
  }
}

@media screen and (min-width: 769px) {
  #statistics {
    width: 100%;
    max-width: 100%;
  }
}

#statistics .wrapper {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: start;
  align-items: flex-start;
  display: -ms-flexbox;
  display: flex;
}

@media screen and (min-width: 769px) {
  #statistics .wrapper {
    -ms-flex-direction: row;
    flex-direction: row;
    padding-right: 3rem;
  }
}

#statistics .statistics-text {
  max-width: 26rem;
}

#statistics .statistics-numbers {
  width: 100%;
  margin-top: 3rem;
}

@media screen and (min-width: 769px) {
  #statistics .statistics-numbers {
    margin-top: 0;
    padding-left: 3rem;
  }
}

#statistics .statistics-numbers .stat {
  text-align: left;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: start;
  justify-content: flex-start;
  margin-bottom: 30px;
  display: -ms-flexbox;
  display: flex;
}

@media screen and (min-width: 769px) {
  #statistics .statistics-numbers .stat {
    text-align: right;
  }
}

#statistics .statistics-numbers .stat .percent {
  font-size: 80px;
  font-weight: 500;
  line-height: 100%;
}

@media screen and (min-width: 768px) {
  #statistics .statistics-numbers .stat .percent {
    font-size: 100px;
  }
}

@media screen and (min-width: 1024px) {
  #statistics .statistics-numbers .stat .percent {
    font-size: 120px;
  }
}

#statistics .statistics-numbers .stat .label {
  font-size: 20px;
}

@media screen and (min-width: 1024px) {
  #statistics .statistics-numbers .stat .label {
    font-size: 25px;
  }
}

#links {
  width: 100%;
}

#links .link-container {
  padding: 20px;
}

@media screen and (min-width: 768px) {
  #links {
    max-width: 100%;
    height: 100%;
  }

  #links .link-container {
    width: 100%;
    height: 100%;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    padding-top: 40px;
    display: -ms-flexbox;
    display: flex;
  }

  #links .link-intro {
    max-width: 480px;
    text-align: center;
    margin: 30px auto 50px;
  }

  #links h2 {
    text-align: center;
  }
}

#links ul {
  list-style: none;
}

@media screen and (min-width: 768px) {
  #links ul {
    max-width: 980px;
    grid-column-gap: 30px;
    grid-row-gap: 20px;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(2, 1fr);
    display: grid;
  }
}

@media screen and (min-width: 1024px) {
  #links ul {
    grid-column-gap: 80px;
  }
}

#links ul li {
  margin-bottom: 25px;
}

#links ul .title {
  margin-bottom: .5rem;
  font-size: 1.2rem;
  font-weight: 500;
  text-decoration: none;
}

#links ul a {
  pointer-events: all;
}

#links ul p {
  margin-bottom: 0;
}

#links .flex-column {
  height: 100%;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: end;
  justify-content: flex-end;
  display: -ms-flexbox;
  display: flex;
}

#links .accessibility {
  color: #fff;
  text-align: center;
  background: #025169;
  padding: 20px;
}

@media screen and (min-width: 768px) {
  #links .accessibility {
    text-align: left;
  }
}

#links .accessibility .accessibility-content {
  max-width: 980px;
  margin: 0 auto;
}

#links .accessibility h3 {
  font-size: 18px;
  font-weight: 600;
}

#links .accessibility p, #links .accessibility a {
  font-size: 18px;
}

#links .accessibility a {
  text-decoration: underline;
}

#links .accessibility p {
  max-width: 400px;
  margin-top: 20px;
  margin-bottom: 0;
}

.health-buttons-container {
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
}

.health-buttons-container button {
  color: #fff;
  background: rgba(255, 255, 255, .1);
  border-radius: 50px;
  margin: 0 3px;
  padding: 8px 12px;
  font-size: 16px;
  transition: color .25s, background .25s;
}

@media screen and (min-width: 768px) {
  .health-buttons-container button {
    margin: 0 8px;
    padding: 8px 15px;
    font-size: 18px;
  }
}

.health-buttons-container button.active, .health-buttons-container button:hover {
  color: #025169;
  background: #fff;
}

@media screen and (min-width: 768px) {
  #smuggling {
    top: 50%;
  }
}

#smugglingGame {
  width: 100%;
  max-width: 100%;
  text-align: center;
}

#smugglingGame .texts p {
  font-size: 20px;
  display: none;
}

#smugglingGame .game-intro {
  max-width: 500px;
  margin: 0 auto;
}

#smugglingGame .game-intro p {
  font-size: 20px;
}

#smugglingGame .play-game {
  pointer-events: all;
  color: #fff;
  background: #025169;
  border-radius: 10px;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 auto;
  padding: 5px 15px 5px 10px;
  transition: transform .15s ease-out;
  display: -ms-flexbox;
  display: flex;
}

#smugglingGame .play-game svg {
  width: 30px;
  height: 30px;
  fill: currentColor;
  margin-right: 5px;
  display: block;
}

#smugglingGame .play-game:hover {
  transform: scale(1.05);
}

.hotspot-content {
  width: 500px;
  max-width: calc(100% - 4rem);
  max-height: 70vh;
  box-sizing: border-box;
  z-index: 999;
  color: #025169;
  background: #fff;
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  overflow: scroll;
  transform: translate(-50%, -50%);
  box-shadow: 0 1rem 1rem rgba(2, 81, 105, .2);
}

@media screen and (min-width: 768px) {
  .hotspot-content.wide {
    max-width: 520px;
  }
}

.hotspot-content p {
  margin: 1.5rem 0 0;
}

.hotspot-content h3 {
  margin: 0;
}

.hotspot-content .scrollable {
  width: 100%;
  max-height: 100%;
  box-sizing: border-box;
  background: #fff;
  padding: 4rem 2rem 2rem;
  position: relative;
  overflow: auto;
}

@media screen and (min-width: 768px) {
  .hotspot-content .scrollable {
    max-height: none;
  }
}

.hotspot-content .close-btn {
  height: auto;
  z-index: 1;
  font-size: inherit;
  color: #025169;
  background: none;
  position: fixed;
  top: 20px;
  right: 20px;
  overflow: hidden;
}

.hotspot-content .close-btn svg {
  fill: currentColor;
}

.hotspot-container {
  display: none;
  position: absolute;
}

.hotspot-container button.hotspot {
  font-size: inherit;
  color: #047fa4;
  background-color: rgba(0, 0, 0, 0);
  transition: opacity .35s;
  position: absolute;
}

.hotspot-container button.hotspot:hover .btn {
  transform: scale(1.2);
}

.hotspot-container button.hotspot .btn {
  width: 40px;
  height: 40px;
  color: #047fa4;
  background: #fff;
  border-radius: 50%;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  transition: transform .35s cubic-bezier(.19, 1, .22, 1);
  display: -ms-flexbox;
  display: flex;
}

.hotspot-container button.hotspot .btn svg {
  width: 60%;
  color: currentColor;
}

.hotspot-container button.hotspot .label {
  height: 30px;
  color: #025169;
  white-space: nowrap;
  background-color: #fff;
  border-radius: 3px;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 7px;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  top: 5px;
  left: 50px;
}

.hotspot-container button.hotspot.hide {
  opacity: 0;
}

#jointRoulette svg {
  margin-bottom: 20px;
}

.about {
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.about .about-content-wrapper {
  width: calc(100% - 4rem);
  max-width: 600px;
  max-height: 70vh;
  background: #fff;
  position: relative;
  box-shadow: 0 1rem 1rem rgba(2, 81, 105, .2);
}

.about .about-content-wrapper:after {
  height: 3rem;
  content: "";
  pointer-events: none;
  background: linear-gradient(rgba(255, 255, 255, 0), #fff);
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.about .about-content-wrapper .about-content {
  height: 100%;
  max-height: 70vh;
  -webkit-overflow-scrolling: touch;
  padding: 5rem 2rem 2rem;
  overflow: scroll;
}

@media screen and (min-width: 768px) {
  .about .about-content-wrapper .about-content {
    padding: 5rem 4rem 4rem;
  }
}

.about .about-content-wrapper #aboutClose {
  background-color: #fff;
  position: absolute;
}

.about .about-content-wrapper h2, .about .about-content-wrapper p {
  width: 100%;
  max-width: 600px;
}

.about .about-content-wrapper p {
  margin-bottom: 1rem;
}

.about .about-content-wrapper a {
  text-decoration: underline;
}

.dark .about {
  color: #025169;
}

.cookies {
  width: 100%;
  z-index: 2;
  text-align: center;
  display: none;
  position: absolute;
  bottom: 30px;
}

.cookies .cookie-wrapper {
  width: auto;
  max-width: calc(100% - 4rem);
  box-sizing: border-box;
  color: #000;
  text-align: center;
  background: #fff;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 0 auto;
  padding: 25px;
  display: -ms-inline-flexbox;
  display: inline-flex;
  box-shadow: 0 1rem 1rem rgba(2, 81, 105, .2);
}

@media screen and (min-width: 768px) {
  .cookies .cookie-wrapper {
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .cookies .cookie-wrapper p {
    margin-bottom: 0;
  }

  .cookies .cookie-wrapper button {
    margin-left: 3rem;
  }
}

.cookies p {
  margin-bottom: 1rem;
}

.cookies a {
  color: #025169;
  border-bottom: 2px solid #047fa4;
}

.cookies a:hover {
  border-bottom: 2px solid #025169;
}

.cookies .cookie-close button {
  font-size: inherit;
  color: #025169;
  background: none;
  border-bottom: 2px solid #047fa4;
}

.cookies .cookie-close button:hover {
  border-bottom: 2px solid #025169;
}

.cookies .long {
  display: none;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body {
  height: 100%;
  min-height: 100%;
  font-smooth: always;
  color: #025169;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: futura-pt, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

html.dark, body.dark {
  color: #fff;
}

body:after {
  content: "small";
  display: none;
}

@media screen and (min-width: 768px) {
  body:after {
    content: "medium";
  }
}

@media screen and (min-width: 1024px) {
  body:after {
    content: "large";
  }
}

@media screen and (min-width: 1280px) {
  body:after {
    content: "xlarge";
  }
}

a {
  color: currentColor;
  text-decoration: none;
}

button {
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  border: none;
  font-family: inherit;
}

#app {
  width: 100%;
  height: 100%;
  position: relative;
  overflow-x: hidden;
}

#canvas {
  width: 100%;
  height: 100%;
  position: fixed;
}

.video-drawer {
  width: 100%;
  max-width: 400px;
  min-height: 100%;
  color: #000;
  background: #fff;
  padding: 80px 2rem;
  display: none;
  position: absolute;
  top: 0;
  right: 0;
}

.video-drawer.show {
  display: block;
}

.video-drawer h2 {
  color: #025169;
  text-transform: initial;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 300;
}

.video-drawer h3 {
  color: #025169;
  margin-bottom: 2rem;
  font-size: 20px;
}

.video-drawer p {
  color: #025169;
}

.video-drawer .player {
  background: #025169;
  margin-bottom: 10px;
  padding-bottom: 56.25%;
  position: relative;
}

.video-drawer .player iframe {
  position: absolute;
}

.video-drawer .player svg {
  width: 40px;
  fill: rgba(255, 255, 255, .5);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.start-btn {
  width: 50px;
  height: 50px;
  background: #fff;
  border-radius: 50%;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 auto;
  display: -ms-flexbox;
  display: flex;
}

.start-btn svg {
  width: 25px;
  height: 25px;
  fill: #cae0e0;
}

.close-btn {
  color: #025169;
  background: none;
  border: none;
  border-bottom: 2px solid #047fa4;
  position: absolute;
  top: 2rem;
  right: 2rem;
}

/*# sourceMappingURL=index.7d544b17.css.map */
