@import url("https://use.typekit.net/dex0etk.css");

// // Futura PT Medium
// font-family: futura-pt, sans-serif;
// font-weight: 500;
// font-style: normal;
// // Futura PT Book
// font-family: futura-pt, sans-serif;
// font-weight: 400;
// font-style: normal;
// // Futura PT Bold
// font-family: futura-pt-bold, sans-serif;
// font-weight: 700;
// font-style: normal;
