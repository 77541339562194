.cookies {
  position: absolute;
  bottom: 30px;

  display: none;

  width: 100%;

  z-index: 2;

  text-align: center;

  .cookie-wrapper {
    display: inline-flex;
    flex-direction: column;

    margin: 0 auto;
    padding: 25px;
    width: auto;
    max-width: calc(100% - 4rem);

    box-sizing: border-box;

    color: black;
    text-align: center;

    background: white;
    box-shadow: 0 1rem 1rem rgba($darkBlue, 0.2);

    @media screen and (min-width: 768px) {
      flex-direction: row;
      justify-content: space-between;

      p {
        margin-bottom: 0;
      }

      button {
        margin-left: 3rem;
      }
    }
  }

  p {
    margin-bottom: 1rem;
  }

  a {
    color: $darkBlue;

    border-bottom: 2px solid $blue;

    &:hover {
      border-bottom: 2px solid $darkBlue;
    }
  }

  .cookie-close button {
    font-size: inherit;

    color: $darkBlue;

    border-bottom: 2px solid $blue;

    background: none;

    &:hover {
      border-bottom: 2px solid $darkBlue;
    }
  }

  .long {
    display: none;
  }
}
