$mediumScreen: 768px;
$largeScreen: 1024px;
$xlargeScreen: 1280px;
$smallHeight: 680px;

@mixin medium {
  @media screen and (min-width: $mediumScreen) {
    @content;
  }
}

@mixin large {
  @media screen and (min-width: $largeScreen) {
    @content;
  }
}

@mixin xLarge {
  @media screen and (min-width: $xlargeScreen) {
    @content;
  }
}

@mixin smallHeight {
  @media screen and (max-height: $smallHeight) {
    @content;
  }
}
