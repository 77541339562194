.content {
  position: fixed;

  width: 100%;
  height: 100%;

  overflow: hidden;

  transition: $colorTransition;

  perspective: 700px;
}

.block {
  position: absolute;

  display: none;

  max-width: 35rem;

  pointer-events: none;

  .cta {
    display: inline;

    font-size: 20px;
    font-weight: 500;

    border-bottom: 2px solid $blue;

    @include smallHeight {
      font-size: 18px;
    }
  }
}

.no-touch .block {
  @include medium {
    pointer-events: all;
  }
}

.text-block {
  padding: 0 2rem;

  @include medium {
    padding: 0 4rem;
  }

  ul {
    list-style: none;

    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

#introText {
  position: absolute;
  bottom: 2rem;
  left: 50%;

  width: 80%;

  transform: translate(-50%, 0);

  @include medium {
    bottom: 7%;
  }

  p {
    margin: 0.5rem auto 1rem;
    max-width: 20rem;

    @include medium {
      margin: 2rem auto;
      max-width: 30rem;

      font-size: 24px;
    }
  }
}

.top-text {
  top: 120px;

  @include medium {
    top: 200px;
  }
}

.bottom-text {
  bottom: 0;
}

.push-text {
  top: 100vh;
}

.middle-text {
  top: 50%;

  transform: translateY(-50%);
}

#healthButtons {
  position: absolute;
  top: 90px;

  display: none;

  width: 100%;

  @include medium {
    top: 120px;
  }
}

#healthMessages {
  position: absolute;
  top: 40%;
  left: 50%;

  display: none;

  width: 100%;
  max-width: 720px;

  pointer-events: none;

  text-align: center;

  transform: translate(-50%, -50%);

  p {
    position: absolute;
    top: 0;
    left: 0;

    padding: 0 35px;
    width: 100%;

    font-size: 22px;

    @include medium {
      font-size: 34px;
    }
  }
}

#statistics {
  @media screen and (min-width: 769px) {
    width: 100%;
    max-width: 100%;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    @media screen and (min-width: 769px) {
      flex-direction: row;

      padding-right: 3rem;
    }
  }

  .statistics-text {
    max-width: calc(30rem - 4rem);
  }

  .statistics-numbers {
    margin-top: 3rem;
    width: 100%;

    @media screen and (min-width: 769px) {
      margin-top: 0;
      padding-left: 3rem;
    }

    .stat {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      margin-bottom: 30px;

      text-align: left;

      @media screen and (min-width: 769px) {
        text-align: right;
      }

      .percent {
        font-size: 80px;
        font-weight: 500;

        line-height: 100%;

        @include medium {
          font-size: 100px;
        }

        @include large {
          font-size: 120px;
        }

        // @include xLarge {
        //   font-size: 150px;
        // }
      }

      .label {
        font-size: 20px;

        @include large {
          font-size: 25px;
        }
      }
    }
  }
}

#links {
  width: 100%;

  .link-container {
    padding: 20px;
  }

  @media screen and (min-width: 768px) {
    max-width: 100%;
    height: 100%;

    .link-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 40px;

      width: 100%;
      height: 100%;
    }

    .link-intro {
      margin: 30px auto 50px;
      max-width: 480px;

      text-align: center;
    }
  }

  @media screen and (min-width: 768px) {
    h2 {
      text-align: center;
    }
  }

  ul {
    list-style: none;

    @include medium {
      display: grid;
      grid-template-rows: repeat(2, 1fr);
      grid-template-columns: repeat(2, 1fr);

      max-width: 980px;

      grid-column-gap: 30px;
      grid-row-gap: 20px;
    }

    @include large {
      grid-column-gap: 80px;
    }

    li {
      margin-bottom: 25px;
    }

    .title {
      margin-bottom: 0.5rem;

      font-size: 1.2rem;
      font-weight: 500;

      text-decoration: none;
    }

    a {
      pointer-events: all;
    }

    p {
      margin-bottom: 0;
    }
  }

  .flex-column {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
  }


  .accessibility {
    background: $darkBlue;
    padding: 20px;
    color: white;

    text-align: center;

    @include medium {
      text-align: left;
    }

    .accessibility-content {
      margin: 0 auto;
      max-width: 980px;
    }

    h3 {
      font-size: 18px;
      font-weight: 600;
    }

    p,
    a {
      font-size: 18px;
    }

    a {
      text-decoration: underline;
    }

    p {
      margin-top: 20px;
      margin-bottom: 0;
      max-width: 400px;
    }
  }
}

.health-buttons-container {
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    margin: 0 3px;
    padding: 8px 12px;

    font-size: 16px;

    color: white;

    border-radius: 50px;

    background: rgba(white, 0.1);

    transition: color 0.25s ease, background 0.25s ease;

    @include medium {
      margin: 0 8px;
      padding: 8px 15px;

      font-size: 18px;
    }

    &.active,
    &:hover {
      color: $darkBlue;

      background: white;
    }
  }
}

#smuggling {
  @include medium {
    top: 50%;
  }
}

#smugglingGame {
  width: 100%;
  max-width: 100%;

  text-align: center;

  .texts p {
    display: none;

    font-size: 20px;
  }

  .game-intro {
    margin: 0 auto;
    max-width: 500px;

    p {
      font-size: 20px;
    }
  }

  .play-game {
    display: flex;
    justify-content: center;
    align-items: center;

    margin: 0 auto;
    padding: 5px 15px 5px 10px;

    pointer-events: all;

    color: white;

    border-radius: 10px;

    background: $darkBlue;

    transition: transform 0.15s ease-out;

    svg {
      display: block;

      margin-right: 5px;
      width: 30px;
      height: 30px;

      fill: currentColor;
    }

    &:hover {
      transform: scale(1.05);
    }
  }
}

// hotspots

.hotspot-content {
  position: absolute;
  top: 50%;
  left: 50%;

  display: none;

  width: 500px;
  max-width: calc(100% - 4rem);
  // max-height: calc(100vh - 30%);
  max-height: 70vh;

  box-sizing: border-box;

  overflow: scroll;

  z-index: 999;

  color: $darkBlue;

  background: white;
  box-shadow: 0 1rem 1rem rgba($darkBlue, 0.2);

  transform: translate(-50%, -50%);

  @include medium {
    &.wide {
      max-width: 520px;
    }
  }

  p {
    margin: 1.5rem 0 0 0;
  }

  h3 {
    margin: 0;
  }

  .scrollable {
    position: relative;

    padding: 4rem 2rem 2rem 2rem;
    width: 100%;
    max-height: 100%;

    box-sizing: border-box;

    overflow: auto;

    background: white;

    @include medium {
      max-height: none;
    }
  }

  .close-btn {
    position: fixed;
    top: 20px;
    right: 20px;

    height: auto;

    overflow: hidden;

    z-index: 1;

    font-size: inherit;

    color: $darkBlue;

    background: none;

    svg {
      fill: currentColor;
    }
  }
}

.hotspot-container {
  position: absolute;

  display: none;

  button.hotspot {
    position: absolute;

    font-size: inherit;

    color: $blue;

    background-color: transparent;

    transition: opacity 0.35s ease;

    &:hover {
      .btn {
        transform: scale(1.2);
      }
    }

    .btn {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 40px;
      height: 40px;

      color: $blue;

      border-radius: 50%;

      background: white;

      transition: transform 0.35s $ease-out-expo;

      svg {
        width: 60%;

        color: currentColor;
      }
    }

    .label {
      position: absolute;
      top: 5px;
      left: 50px;

      display: flex;
      justify-content: center;
      align-items: center;

      padding: 0 7px;
      height: 30px;

      color: $darkBlue;
      white-space: nowrap;

      border-radius: 3px;

      background-color: white;
    }

    &.hide {
      opacity: 0;
    }
  }
}

#jointRoulette {
  svg {
    margin-bottom: 20px;
  }
}