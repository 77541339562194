#logo {
  position: fixed;
  top: 2rem;
  left: 2rem;

  width: 48px;

  overflow: hidden;

  font-size: inherit;

  color: $darkBlue;

  background: none;

  @include medium {
    top: 4rem;
    left: 4rem;

    width: 232px;
  }

  svg {
    height: 30px;

    transition: color 0.5s ease;

    fill: currentColor;
    .small-hide {
      display: none;

      @include medium {
        display: block;
      }
    }
  }
}

.menu {
  position: fixed;
  top: 2rem;
  right: 2rem;

  display: flex;

  font-size: 18px;

  transition: $colorTransition;

  @include medium {
    top: 4rem;
    right: 4rem;
  }

  ul.links {
    display: flex;
    padding-top: 3px;
    list-style: none;

    li {
      display: flex;

      margin-right: 15px;
      position: relative;

      &:last-child {
        margin-right: 0;
      }

      &.current {
        a {
          font-weight: 500;

          border-bottom: none;
        }
      }
      a {
        border-bottom: 2px solid $blue;

        transition: $borderTransition;
      }

      svg {
        margin-left: 5px;
        height: 25px;

        transition: $fillTransition;

        fill: $blue;
      }
    }
  }

  ul.lang-list {
    position: absolute;
    top: 35px;
    left: 0;
    background: white;
    padding: 5px;
    box-shadow: 0 1rem 1rem rgba($darkBlue, 0.2);
    width: 100%;
    display: none;

    &.visible {
      display: block;
    }

    li {
      margin-right: 0;

      a {
        border-bottom: none;
        display: block;
        width: 100%;
        padding: 5px;

        &:hover {
          background: rgba($lightBlue, 0.4);
        }
      }
    }
  }
}

.content-menu {
  position: fixed;
  top: 50%;
  right: 15px;

  color: $blue;

  transform: translateY(-50%);

  transition: $colorTransition;

  @include medium {
    right: 30px;
  }

  ul {
    list-style: none;
  }

  li {
    margin: 8px 0;
    a {
      position: relative;

      display: block;

      width: 20px;
      height: 20px;

      border: 2px solid rgba($blue, 0);
      border-radius: 50%;

      transition: border-color 0.3s ease;

      @include medium {
        width: 24px;
        height: 24px;
      }

      &.frontpage {
        &:before {
          display: none;
        }
      }
      &:before {
        position: absolute;
        top: 50%;
        left: 50%;

        display: block;

        width: 6px;
        height: 6px;

        content: '';

        border-radius: 50%;

        background: currentColor;

        transform: translate(-50%, -50%);

        @include medium {
          width: 8px;
          height: 8px;
        }
      }
      &:hover {
        border: 2px solid rgba($blue, 1);

        @include medium {
          span {
            visibility: visible;

            opacity: 1;

            transform: translateX(0);
          }
        }
      }

      &.active,
      &:focus {
        border: 2px solid rgba($blue, 1);
      }

      svg {
        position: absolute;
        top: 50%;
        left: 50%;

        width: 12px;

        transform: translate(-50%, -50%);

        fill: currentColor;
        @include medium {
          width: 14px;
        }
      }

      span {
        display: none;

        @include medium {
          position: absolute;
          right: 28px;

          display: block;

          visibility: hidden;

          opacity: 0;

          transform: translateX(-5px);

          transition: opacity 0.35s ease, transform 0.35s ease;
        }
      }
    }
  }
}

/*
THEME
*/

.dark {
  #logo svg {
    color: white;
  }

  .menu ul li {
    a {
      border-color: $lightBlue;
    }
    svg {
      fill: $lightBlue;
    }
  }

  .content-menu ul li a {
    &:hover,
    &.active {
      border-color: $blue;
    }
  }
}
