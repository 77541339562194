@import 'typo';

.loader {
  position: absolute;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  .text {
    @extend .h2;
  }

  .progress {
    margin-top: 10px;
    width: 150px;
    height: 12px;

    border: 2px solid $darkBlue;
    border-radius: 10px;

    @include medium {
      width: 160px;
    }

    .bar {
      width: 0%;
      height: 100%;

      background: $darkBlue;
    }
  }
}
